import { filter, isArray } from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'

import appModule from './modules/app'
import login from './modules/login'
import user from './modules/user'
import products from './modules/products'
import techbar from './modules/techbar'
import storeSigns from './modules/storeSigns'
import aisleGuide from './modules/aisleGuide'
import buildYourOwn from './modules/buildYourOwn'
import survey from './modules/survey'


Vue.use(Vuex)

export const STATE_STALE = 'STATE_STALE'
export const RESTORE_MUTATION = 'RESTORE_MUTATION'

export const SNACKBAR_ADD = 'SNACKBAR_ADD'
export const SNACKBAR_REMOVE = 'SNACKBAR_REMOVE'

export const COLOR_WARNING = 'pink lighten-1'
export const COLOR_ERROR = 'red darken-1'
export const COLOR_INFO = 'blue darken-2'
export const COLOR_SUCCESS = 'green darken-1'

const defaultSnackbarOpts = {
  color: 'success',
  timeout: 5000,
  model: true,
}

export default new Vuex.Store({
  strict: true,
  state: {
    state: STATE_STALE,
    snackBars: [],
  },
  actions: {
    async [SNACKBAR_ADD] ({ commit }, payload) {
      const id = +(new Date())
      const opts = {
        ...defaultSnackbarOpts,
        ...payload,
        id,
      }
      await commit(SNACKBAR_ADD, opts)
    },
  },
  mutations: {
    async [RESTORE_MUTATION] (s) {
      s.app.isRestored = true
    },
    async [SNACKBAR_ADD] (s, payload) {
      console.log('call snackbar add first', payload);
      if (!s.snackBars) {
        s.snackBars = []
      }
      s.snackBars.push(payload)
    },
    async [SNACKBAR_REMOVE] (s, id) {
      if (id === 0) {
        s.snackBars = []
      } else if (isArray(id)) {
        s.snackBars = id
      } else {
        s.snackBars = filter(s.snackBars, { id })
      }
    },
  },
  modules: {
    app: appModule,
    login,
    user,
    products,
    techbar,
    storeSigns,
    aisleGuide,
    buildYourOwn,
    survey
  }
})

export function defaultPaging () {
  return {
    q: null,
    page: 1,
    limit: 10,
  }
}

export function updatePaging ({ page, limit, pages, total }) {
  return {
    page: page,
    limit: limit,
    pages: pages,
    total: total,
  }
}
