import Vue from 'vue'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required,
  digits,
  alpha_spaces,
  confirmed,
} from 'vee-validate/dist/rules'

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})
extend('email', email)
extend('max', max)
extend('numeric', numeric)
extend('required', required)
extend('alpha_spaces', alpha_spaces)
extend('confirmed', {
  ...confirmed,
  message: '{_field_} does not match'
})

extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters',
})
extend('verify_password', {
  message: 'The {_field_} must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  validate: value => {
    // eslint-disable-next-line no-useless-escape
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return strongRegex.test(value);
}
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
