import Vue from 'vue'
import App from './App.vue'
import './plugins/api'
import './plugins/base'
import './plugins/vee-validate'
import './plugins/amplify'
import vuetify from './plugins/vuetify';
import router from './router/Index'
import './styles/app.css'

import { mapState } from 'vuex'
import store, {SNACKBAR_REMOVE} from './store/Index'
import { get, find } from 'lodash'

Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    ...mapState({
      role: (state) => state.app.user.group,
    }),
    snackBars: {
      get () {
        return this.$store.state.snackBars
      },
      set (val) {
        this.$store.commit(SNACKBAR_REMOVE, val)
      },
    },
  },
  methods: {
    resetSnackBars () {
      this.$store.commit(SNACKBAR_REMOVE, 0)
    },
    getCompanyInfo (id) {
      const { app } = this.$store.state
      const companies = get(app, 'appData.companies', [])
      return find(companies, { id }) || {}
    },
    getUserInitial (name) {
      return name.split(' ').map((x) => ((x || '').split()[0] || '').toUpperCase()).join('')
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
