import Vue from 'vue'
import VueRouter from 'vue-router'
import store, { RESTORE_MUTATION } from '@/store/Index'
import { REQUEST_SIGNOUT } from '@/store/modules/login'


import {
  ROUTE_SIGNIN,
  ROUTE_CONFIRM,
  ROUTE_SIGNOUT,
  ROUTE_RESET_PASSWORD,
  ROUTE_DASHBOARD,
  ROUTE_USERS,
  ROUTE_TECH_BAR,
  ROUTE_STORE_SIGNS,
  ROUTE_AISLE_GUIDE,
  ROUTE_BUILD_YOUR_OWN,
  ROUTE_SURVEY,
} from '@/constants'

//utilities
import {
  kebabCase,
  // startCase,
  get
} from 'lodash'


Vue.use(VueRouter)
export const toUrl = (path) => `/${kebabCase(path).toLowerCase()}`

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      {
        path: '',
        redirect: { name: ROUTE_DASHBOARD },
      },
      {
        name: ROUTE_DASHBOARD,
        path: toUrl(ROUTE_DASHBOARD),
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
          auth: true,
          withSession: true,
        },
        // props: {
        //   routeName: ROUTE_DASHBOARD,
        // },
      },
      {
        name: ROUTE_USERS,
        path: toUrl(ROUTE_USERS),
        component: () => import('@/views/users/Users'),
        meta: {
          auth: true,
          withSession: true,
        },
        props: {
          routeName: ROUTE_USERS,
        },
      },
      // to be added
      // { 
      //   name: ROUTE_PRODUCTS,
      //   path: toUrl(ROUTE_PRODUCTS),
      //   component: () => import('@/views/products/Products'),
      //   meta: {
      //     auth: true,
      //     withSession: true,
      //   },
      //   props: {
      //     routeName: ROUTE_PRODUCTS,
      //   },
      // },
      {
        name: ROUTE_TECH_BAR,
        path: toUrl(ROUTE_TECH_BAR),
        component: () => import('@/views/techBar/TechBar'),
        meta: {
          auth: true,
          withSession: true,
        },
        props: {
          routeName: ROUTE_TECH_BAR,
        },
      },
      {
        name: ROUTE_BUILD_YOUR_OWN,
        path: toUrl(ROUTE_BUILD_YOUR_OWN),
        component: () => import('@/views/buildYourOwn/BuildYourOwn'),
        meta: {
          auth: true,
          withSession: true,
        },
        props: {
          routeName: ROUTE_BUILD_YOUR_OWN,
        },
      },
      {
        name: ROUTE_STORE_SIGNS,
        path: toUrl(ROUTE_STORE_SIGNS),
        component: () => import('@/views/storeSigns/StoreSigns'),
        meta: {
          auth: true,
          withSession: true,
        },
        props: {
          routeName: ROUTE_STORE_SIGNS,
        },
      },
      {
        name: ROUTE_AISLE_GUIDE,
        path: toUrl(ROUTE_AISLE_GUIDE),
        component: () => import('@/views/aisleGuide/AisleGuide'),
        meta: {
          auth: true,
          withSession: true,
        },
        props: {
          routeName: ROUTE_AISLE_GUIDE,
        },
      },
      {
        name: ROUTE_SURVEY,
        path: toUrl(ROUTE_SURVEY),
        component: () => import('@/views/survey/Survey'),
        meta: {
          auth: true,
          withSession: true,
        },
        props: {
          routeName: ROUTE_SURVEY,
        },
      },
    ],
  },
]

const routeAuths = [
  {
    path: toUrl(ROUTE_SIGNIN),
    name: ROUTE_SIGNIN,
    component: () => import('@/views/login/signin/SignIn'),
    meta: {
      auth: false,
    },
    props: {
      routeName: ROUTE_SIGNIN,
    },
  },
  {
    path: toUrl(ROUTE_CONFIRM),
    name: ROUTE_CONFIRM,
    component: () => import('@/views/login/confirm/Confirm'),
    meta: {
      auth: false,
    },
    props: {
      routeName: ROUTE_CONFIRM,
    },
  },
  {
    path: toUrl(ROUTE_RESET_PASSWORD),
    name: ROUTE_RESET_PASSWORD,
    component: () => import('@/views/login/forgot/Forgot'),
    meta: {
      auth: false,
    },
    props: {
      routeName: ROUTE_CONFIRM,
    },
  },
  {
    path: toUrl(ROUTE_SIGNOUT),
    name: ROUTE_SIGNOUT,
    alias: '/signout',
    redirect: () => {
      store.dispatch(REQUEST_SIGNOUT)
      return { name: ROUTE_SIGNIN }
    },
    meta: {
      auth: false,
    },
  },
]

const mapping = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [].concat(routes).concat(routeAuths).concat([]),
}

const router = new VueRouter(mapping)
router.beforeEach(async (to, from, next) => {
  if (get(store.state, 'app.isRestored', false) === false) {
    await store.restored
    await store.commit(RESTORE_MUTATION)
  }
  return next()
})
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

export default router
