

// import { rootDrawerMenuList, drawerMenuList } from '@/router'
// import { filter, cloneDeep, find, first, get, has, indexOf, isEmpty, merge, pick } from 'lodash'
import { cloneDeep, merge, has } from 'lodash'
import { Auth } from 'aws-amplify'

import { COLOR_ERROR, COLOR_INFO, SNACKBAR_ADD } from '@/store/Index' //COLOR_WARNING
import { ROUTE_SIGNIN } from '@/constants'
import { REQUEST_SIGNOUT, RESET_LOGIN_STATE } from './login'

import api from '@/api'
import router from '@/router/Index'






// constants
export const EV_SESSION_EXPIRED = 'EV_SESSION_EXPIRED'

export const RESET_APP_DATA = 'RESET_APP_DATA'
export const APP_ERROR = 'APP_ERROR'
export const UPDATE_LAST_ERROR = 'UPDATE_LAST_ERROR'

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const TOGGLE_MINI = 'TOGGLE_MINI'

export const MODE_STALE = 'MODE_STALE'
export const MODE_FREEZE = 'MODE_FREEZE'
export const MODE_FORBIDDEN = 'MODE_FORBIDDEN'

export const UPDATE_LOGIN_INFORMATION = 'UPDATE_LOGIN_INFORMATION'
export const UPDATE_ADMIN_INFORMATION = 'UPDATE_ADMIN_INFORMATION'

export const LOAD_SESSION = 'LOAD_SESSION'
export const UPDATE_ADMIN = 'UPDATE_ADMIN'



// state
const moduleState = {
  drawer: true,
  mini: false,
  mode: MODE_STALE,
  admin: {
    first_name: '',
    last_name: '',
    email_address: '',
  },
  user: {
    menuList: [],
    rootMenuList: [],
    group: [],
  },
  isReady: false,
  isRestored: false,
  error: null,
  lastError: null,
  appData: {
    notifications: [],
    company: null,
    companyName: null,
    departments: [],
    companyMeta: [],
  },
}

// action

const actions = {
  async [EV_SESSION_EXPIRED] () {
    router.replace({ name: ROUTE_SIGNIN })
  },
  async [LOAD_SESSION] ({ commit, dispatch }) {
    await commit(MODE_FREEZE) // make the app not accessible
    try {
      const user = await Auth.currentAuthenticatedUser()
      const auth = await (new Promise((resolve, reject) => {
        user.getUserAttributes((err, attrs) => {
          if (err) {
            return reject(err)
          }
          const data = {}
          attrs.forEach((attr) => {
            const { Name, Value } = attr.toJSON()
            merge(data, { [Name]: Value })
          })
          resolve(data)
        })
      }))
      if (!has(auth, 'custom:user_id') && !auth.sub) {
        throw new Error('Invalid user details.')
      }
      const res = await api.get(`/v1/employee/${auth.nickname}`)
      // this.admin = res.data.data.item; 
      await commit(UPDATE_ADMIN_INFORMATION, res.data.data.item)

      await commit(UPDATE_LOGIN_INFORMATION, {
        id: auth['custom:user_id'] || null,
        cognito_sub: auth.sub,
      })

      console.log('auth',auth)
      // load app data
      // await dispatch(LOAD_APP_DATA)

      // load user information
      // await dispatch(LOAD_USER_INFORMATION)

      await commit(RESET_LOGIN_STATE)

      await commit(MODE_STALE)
    } catch (error) {
      if (error && error.code) {
        if (error.code === 'PasswordResetRequiredException') {
          await dispatch(REQUEST_SIGNOUT)
          return dispatch(SNACKBAR_ADD, {
            message: 'You are required to change your password.',
            color: COLOR_INFO,
          })
        }
      }
      return dispatch(APP_ERROR, { error })
    }
  },
  async [APP_ERROR] ({ dispatch, commit }, { error }) {
    await commit(UPDATE_LAST_ERROR, error)

    if (typeof error === 'string') {
      if (error === 'No current user') {
        await dispatch(EV_SESSION_EXPIRED)
        return dispatch(SNACKBAR_ADD, {
          message: 'Session expired.',
          color: COLOR_ERROR,
        })
      }
    }

    return dispatch(SNACKBAR_ADD, {
      message: api.parseError(error),
      color: COLOR_ERROR,
    })
  },
}

const mutations = {
  async [MODE_STALE] (s) {
    s.mode = MODE_STALE
    s.code = null
    s.isReady = true
  },
  async [MODE_FREEZE] (s) {
    s.mode = MODE_FREEZE
    s.code = null
    s.isReady = false
    // make page inaccessible
  },
  async [MODE_FORBIDDEN] (s, { code }) {
    s.mode = MODE_FORBIDDEN
    s.code = code
  },
  async [TOGGLE_DRAWER] (s, payload) {
    s.drawer = payload === undefined ? !s.drawer : payload
  },
  async [TOGGLE_MINI] (s, payload) {
    s.mini = payload === undefined ? !s.mini : payload
  },
  async [UPDATE_LOGIN_INFORMATION] (s, payload) {
    s.user = payload
  },
  async [UPDATE_ADMIN_INFORMATION] (s, payload) {
    console.log(s)
    s.admin = payload
  },
  async [RESET_APP_DATA] (s) {
    merge(s, cloneDeep(moduleState))
    s.appData = cloneDeep(moduleState.appData)
  },
  async [UPDATE_LAST_ERROR] (s, error) {
    console.error(error)
    s.lastError = error.message
  },
}

export default {
  state: () => cloneDeep(moduleState),
  actions,
  mutations,
}