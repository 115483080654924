import Amplify from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: 'us-east-2',
    identityPoolRegion: 'us-east-2',
    userPoolId: process.env.VUE_APP_STAGING_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_STAGING_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
})
