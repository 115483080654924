

//utilities
// import moment from 'moment'
// import { rootDrawerMenuList, drawerMenuList } from '@/router'
// import { filter, cloneDeep, find, first, get, has, indexOf, isEmpty, merge, pick } from 'lodash'
// import api, {formatPhone } from '@/api' //api
import { APP_ERROR } from './app'

import { cloneDeep, merge} from 'lodash'
import { defaultPaging, updatePaging } from '../Index' //updatePaging
import api from '@/api' //api //formatPhone

// constants

export const REQUEST_STORE_SIGN_LIST = 'REQUEST_STORE_SIGN_LIST'

export const RESET_STORE_SIGN_STATE = 'RESET_STORE_SIGN_STATE'
export const UPDATE_STORE_SIGN_STATE = 'UPDATE_STORE_SIGN_STATE'


// state
const moduleState = {
  isProcessing: false,
  isFetching: false,
  isFormAdd: false,
  isFormEdit: false,
  search: '',
  tabs: [
    {
      name: 'Aisle',
      isActive: false,
    },
    {
      name: 'Department',
      isActive: false,
    },
  ],
  items: [],
  item: {},
  paging: defaultPaging(),

}

// action

const actions = {
  async [REQUEST_STORE_SIGN_LIST] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_STORE_SIGN_STATE, { isFetching: true })
  
      const { paging } = state
      const aisles_ = await api.get(`/v1/stores/${101}/locations/aisles`, api.parseListingPayload(paging))
      console.log(aisles_)
      const aisle = aisles_?.data?.data?.aisles
      const sliceAisle = aisle.slice(Math.abs(1 - paging.page) * paging.limit, paging.limit * paging.page)

      await commit(UPDATE_STORE_SIGN_STATE, {
        items: sliceAisle.map(data=> ( {...data, name: ('0' + data.code).slice(-2)})),
        paging: updatePaging({page: paging.page, limit: paging.limit, pages: Math.ceil(aisle.length / 10), total: aisle.length})
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_STORE_SIGN_STATE, { isFetching: false })
  }
}

//Mutation
const mutations = {
  async [UPDATE_STORE_SIGN_STATE] (s, payload) {
    merge(s, payload)
    if (payload.items) s.items = [].concat(payload.items || [])
    if (payload.item) s.item = cloneDeep(payload.item)
  },
  async [RESET_STORE_SIGN_STATE] (s) {
    merge(s, cloneDeep(moduleState))
    s.items = []
    s.item = {}
  },
}

export default {
  state: () => cloneDeep(moduleState),
  actions,
  mutations,
}