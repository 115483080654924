<template>
  <v-card
    elevation="2"
    class="tw-pt-0 tw-px-0 tw-rounded-t-xl"
  >
  <v-app-bar color="primary" dense dark class="tw-mb-2">
    <!-- MENU FOR PRODUCTS -->
    <v-app-bar-nav-icon v-if="!isHideMenu">
      <v-icon v-if="isTuneIcon">mdi-tune</v-icon>
    </v-app-bar-nav-icon>

    <v-tabs v-if="tabs" class="tw-px-2">
      <v-tab v-for="(tab,index) in tabs" :key="index">{{tab.name}}</v-tab>
    </v-tabs>
    <v-spacer v-if="!tabs"></v-spacer>
    <v-text-field
      v-if="search || search === ''"
      class="tw-py-0 tw-px-3 tw-hidden sm:tw-block sm:tw-w-1/2 md:tw-w-1/4 tw-flex-grow-0"
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-icon v-if="!isHideAdd" @click="onAdd ? onAdd() : false" class="tw-cursor-pointer">mdi-plus</v-icon>
    <v-icon v-if="!isHideSettings" class="tw-cursor-pointer">mdi-dots-vertical</v-icon>
  </v-app-bar>

  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :server-items-length="paging.total"
    :search="search"
    :loading="isLoading"
    loading-text="Loading... Please wait"
    :footer-props="footerProps"
    :single-expand="true"
    :expanded.sync="expanded"
    :show-expand="showExpand"
    item-key="name"
  >
    <template v-if="search" v-slot:top>
      <v-text-field
        v-model="search"
        label="Search"
        class="mx-4 sm:tw-hidden"
      ></v-text-field>
    </template>
    <!-- For Users Page -->
    <template v-if="$route.name === ROUTE_USERS" v-slot:header.status>
      <v-icon style="font-size:8px;" class="">mdi-circle</v-icon>
    </template>
    <template v-if="$route.name === ROUTE_USERS" v-slot:item.status="{item}">
      <v-icon v-if="$route.name === ROUTE_USERS && statusBorder" style="font-size:8px;"
      :class="{'tw-text-success' : item.status === 'active',
                'tw-text-error' : item.status === 'inactive',
                'tw-text-pending' : item.status === 'pending'}">
        mdi-circle
      </v-icon>
    </template>
    <!--End For Users Page -->
    <template v-else-if="$route.name === ROUTE_BUILD_YOUR_OWN || $route.name === ROUTE_AISLE_GUIDE|| $route.name === ROUTE_TECH_BAR || $route.name === ROUTE_PRODUCTS || $route.name === ROUTE_STORE_SIGNS" v-slot:item.status="{item}">
      <div :class="{'tw-text-success' : item.status === 'complete',
                    'tw-text-error' : item.status === 'response',
                    'tw-text-accent' : item.status === 'requested',
                    'tw-bg-success tw-text-success tw-h-12 tw-w-1.5 tw--ml-4' : item.status === 'active' || item.status === 'Open',
                    'tw-bg-error tw-text-error tw-h-12 tw-w-1.5 tw--ml-4' : item.status === 'inactive',
                    'tw-bg-pending tw-text-pending tw-h-12 tw-w-1.5 tw--ml-4' : item.status === 'pending'}">
        <span v-if="$route.name === ROUTE_TECH_BAR || $route.name === ROUTE_BUILD_YOUR_OWN">
          {{item.status}}
        </span>
        <span v-else>-</span>
      </div>
    </template>
    <template v-slot:item.actions="{item}">
      <v-menu
        v-if="itemsAction"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            light
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(action,i) in item.actions"
              :key="i"
              @click="action.action(item)"
            >
              <v-list-item-title class="tw-text-left" :class="action.class">{{ action.label }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
    <template
      v-if="$route.name === ROUTE_USERS"
      v-slot:item.name="{item}"

    >
      <div>
        <v-avatar
          size="24px"
        >
          <img
            v-if="true"
            alt="Avatar"
            src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
          >
          <v-icon v-else>mdi-account-circle</v-icon>
        </v-avatar>
        <span class="tw-px-2">
          {{item.name}}
        </span>
      </div>
    </template>
    <!-- End For Users Page -->
    <template
      v-if="showExpand"
      class="tw-bg-gray-lightest"
      v-slot:expanded-item="{ headers, item }"
    >
      <td class="tw-px-0" :colspan="headers.length">
        <v-container class="tw-py-0" fluid>
          <div v-if="item.sources || item.geo">
            <v-row  v-if="$route.name === ROUTE_AISLE_GUIDE || $route.name === ROUTE_STORE_SIGNS" class="tw-mt-0 tw-mb-1 tw-bg-graySecondary-lightest">
              <v-divider vertical ></v-divider>
              <v-col class="tw-text-left">Descriptions</v-col>
              <v-divider vertical ></v-divider>
              <v-col class="tw-text-left">Long, Lat</v-col>
              <v-divider vertical ></v-divider>
              <v-col class="tw-text-left">Long, Lat</v-col>
              <v-divider vertical ></v-divider>
              <v-col class="tw-text-left">Long, Lat</v-col>
              <v-divider vertical ></v-divider>
              <v-col class="tw-text-left">Long, Lat</v-col>
              <v-divider vertical ></v-divider>
              <v-col class="tw-text-left">Actions</v-col>
            </v-row>
            <div v-if="$route.name === ROUTE_PRODUCTS || $route.name === ROUTE_STORE_SIGNS">
              <v-row class="tw-mt-0 tw-mb-1 tw-bg-graySecondary-lightest" v-for="(subItem,index) in item.sources" :key="index">
                <v-col class="tw-text-left">
                  <v-icon class="tw-text-base tw-px-4">mdi-file-tree-outline</v-icon>
                  {{subItem.name}}
                </v-col>
                <v-col class="tw-text-left">Dept: {{subItem.calories}}</v-col>
                <v-col class="tw-text-left">Isle: {{subItem.calories}}</v-col>
                <v-col class="tw-text-left">Sec: {{subItem.calories}}</v-col>
                <v-col class="tw-text-left"></v-col>
                <v-col class="tw-text-right">
                  <v-icon class="tw-text-base">mdi-pencil</v-icon>
                </v-col>
              </v-row>
            </div>
            <div v-if="$route.name === ROUTE_AISLE_GUIDE || $route.name === ROUTE_STORE_SIGNS">
              <v-row class="tw-mt-0 tw-mb-1 tw-bg-graySecondary-lightest" v-for="(subItem,index) in item.description" :key="index">
                <v-col class="tw-text-left">
                  {{subItem}}
                </v-col>
                <v-divider vertical ></v-divider>
                <v-col class="tw-text-left">{{ item.geo[0] != null ? item.geo[0].coordinate : '' }}</v-col>
                <v-divider vertical ></v-divider>
                <v-col class="tw-text-left">{{ item.geo[1] != null ? item.geo[1].coordinate : '' }}</v-col>
                <v-divider vertical ></v-divider>
                <v-col class="tw-text-left">{{ item.geo[2] != null ? item.geo[2].coordinate : '' }}</v-col>
                <v-divider vertical ></v-divider>
                <v-col class="tw-text-left">{{ item.geo[3] != null ? item.geo[3].coordinate : ''  }}</v-col>
                <v-divider vertical ></v-divider>
              </v-row>
            </div>
          </div>
          <div v-else class="tw-block tw-text-center">
            No data
          </div>
          <v-row v-if="$route.name === ROUTE_PRODUCTS" class="tw-bg-graySecondary-light tw-my-0">
            <v-col cols="12">
              <span class="tw-underline tw-text-primary">Add more location</span>
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>
  </v-data-table>
  </v-card>
</template>

<script>

// components
// import Title from '@/components/atoms/Title'

// Utilities
import {
  // cloneDeep,
  isEqual,
  pick,
  // get
} from 'lodash'

import {
  ROUTE_USERS,
  ROUTE_PRODUCTS,
  ROUTE_TECH_BAR,
  ROUTE_STORE_SIGNS,
  ROUTE_AISLE_GUIDE,
  ROUTE_BUILD_YOUR_OWN
} from '@/constants'

export default {
  name: 'Table',
  props: [
    'title',
    'items',
    'isLoading',
    'headers',
    'tabs',
    'menu',
    'settings',
    'search',
    'isHideSettings',
    'isHideAdd',
    'isHideMenu',
    'footerProps',
    'paging',
    'pagingOptions',
    'showExpand',
    'isTuneIcon',
    'statusBorder',
    'itemsAction',
    'onAdd',

  ],
  components : {
    // Title
  },
  data: () => ({
    ROUTE_USERS: ROUTE_USERS,
    ROUTE_PRODUCTS: ROUTE_PRODUCTS,
    ROUTE_TECH_BAR: ROUTE_TECH_BAR,
    ROUTE_STORE_SIGNS: ROUTE_STORE_SIGNS,
    ROUTE_AISLE_GUIDE: ROUTE_AISLE_GUIDE,
    ROUTE_BUILD_YOUR_OWN: ROUTE_BUILD_YOUR_OWN,
    expanded: [],

  }),
  computed: {
    options:{
      get () {
        const { state } = this.$store
        const { paging } = state[`${this.pagingOptions.state}`]
        const { limit: itemsPerPage, page: page } = paging
        return { page, itemsPerPage }
      },
      async set (p) {
        const {commit, dispatch, state } = this.$store
        const paging = {
          limit: p.itemsPerPage,
          page: p.page,
          sort: [p.sortBy, p.sortDesc],
        }
        if (!isEqual(paging, pick(state[`${this.pagingOptions.state}`].paging, 'limit,page,sort'.split(',')))) {
          await commit(this.pagingOptions.commit, { paging })
          await dispatch(  this.pagingOptions.dispatch )
        }
      },
    },
    haspagingOptions() {
      return this.pagingOptions ? true : false;
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  }
}
</script>

<style lang="sass" scoped>
  .expanded-subItem
    @apply tw-my-2 tw-py-2
</style>