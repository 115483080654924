//utilities
// import { rootDrawerMenuList, drawerMenuList } from '@/router'
// import { filter, cloneDeep, find, first, get, has, indexOf, isEmpty, merge, pick } from 'lodash'
import { AppException, formatPhone } from '@/api' //api
import api from '@/api' //api //formatPhone
import { Auth } from 'aws-amplify'
import { cloneDeep, merge} from 'lodash'
import router from '@/router/Index'
import { RESET_APP_DATA, APP_ERROR } from './app'


// constants
import {
  ROUTE_DASHBOARD,
  ROUTE_SIGNIN,
  ROUTE_CONFIRM,
} from '@/constants'

export const UPDATE_LOGIN_STATE = 'UPDATE_LOGIN_STATE'
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE'

export const REQUEST_SIGNOUT = 'REQUEST_SIGNOUT'
export const REQUEST_SIGNUP = 'REQUEST_SIGNUP'
export const REQUEST_CONFIRM_SIGNUP = 'REQUEST_CONFIRM_SIGNUP'
export const REQUEST_RSEND_CODE = 'REQUEST_RSEND_CODE'
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const REQUEST_NEW_PASSWORD = 'REQUEST_NEW_PASSWORD'

// state
const moduleState = {
  isProcessing: false,
  isFetching: false,
  isResend: false,
  isNewPassword: false,
  challengeName: null,
  timeleft: null,
  admin: {
    first_name: '',
    last_name: '',
    email_address: '',
  },
  form: {
    firstName: '',
    lastName: '',
    type: 'employee',
    email: '',
    username:'',
    code: '',
    password: '',
    phone : '',
  },
}

// action

const actions = {
  async [REQUEST_LOGIN] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      const { username, password , type} = state.form;

      const data = await Auth.signIn(username, 'P@$$w0rd123', { type, password, username});
      const cognitoId = data.username;
      const res = await api.get(`/v1/employee/${cognitoId}`)
      this.admin = res.data.data.item;
      // api.user = await Auth.signIn({ email, password });
      // const { challengeName } = api.user
      // await commit(UPDATE_LOGIN_STATE, { challengeName, isProcessing: false })
      // await commit(UPDATE_LOGIN_STATE, { isProcessing: false});
      await router.push({ name: ROUTE_DASHBOARD });
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      console.log('error', error);
      await dispatch(APP_ERROR, { error })
      await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
    }
  },
  async [REQUEST_SIGNUP] ({ dispatch, commit, state }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      const { username, email, password, firstName, lastName, phone } = state.form

      const params = {
        username,
        email,
        password: 'P@$$w0rd123',
        firstName,
        lastName,
        attributes: {
          username: username,
          email: email,                     // optional
          phone_number: `+${formatPhone(phone)}`,
          given_name : `${firstName}`,
          family_name: `${lastName}`        // optional - E.164 number convention
          // other custom attributes 
        },
        clientMetadata: {
          type: 'customer',
          firstName,
          lastName,
          email,
          password,
          phone: `+${formatPhone(phone)}`,
        }
      }

      await Auth.signUp(params);
      await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
      await commit(RESET_LOGIN_STATE)
      await router.push({ name: ROUTE_CONFIRM, query: { email: email} })
      // setTimeout( async () => {
      //   //if success api request
      //   await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
      //   router.push({ name: ROUTE_SIGNIN })
      // }, 1000)
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
  },
  async [REQUEST_CONFIRM_SIGNUP] ({ dispatch, commit, state }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      const { email, code } = state.form

      await Auth.confirmSignUp( email, code)
      await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
      await commit(RESET_LOGIN_STATE)
      await router.push({ name: ROUTE_SIGNIN })
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
  },
  async [REQUEST_RSEND_CODE] ({ dispatch, commit, state }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      const { email } = state.form

      await Auth.resendSignUp( email )
      await commit(UPDATE_LOGIN_STATE, { isProcessing: false, isResend: true })
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
  },
  async [REQUEST_RESET_PASSWORD] ({ commit, state }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      const { email } = state.form

      await Auth.forgotPassword( email )
      await commit(UPDATE_LOGIN_STATE, { isProcessing: false, isResend: true, isNewPassword: true })
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      // await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
  },
  async [REQUEST_NEW_PASSWORD] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      const { email, code, password, } = state.form
      const params = {
        username: email,
        code: code,
        new_password: password,
      }

      await Auth.forgotPasswordSubmit( params?.username ?? '', params?.code ?? '', params?.new_password ?? '')
      await commit(UPDATE_LOGIN_STATE, { isProcessing: false})
      await router.push({ name: ROUTE_SIGNIN })
      await commit(RESET_LOGIN_STATE)
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
  },
  async [REQUEST_SIGNOUT] ({ dispatch, commit }) {
    try {
      await commit(UPDATE_LOGIN_STATE, { isProcessing: true })
      await Auth.signOut()
      await commit(RESET_LOGIN_STATE)
      await commit(RESET_APP_DATA)
    } catch (err) {
      let error = err
      if (typeof error === 'string') {
        error = new AppException(err)
      }
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_LOGIN_STATE, { isProcessing: false })
  },
  
}

//Mutation
const mutations = {
  async [UPDATE_LOGIN_STATE] (s, payload) {
    merge(s, payload)
    if (payload.form) s.form = cloneDeep(payload.form)
  },
  async [RESET_LOGIN_STATE] (s) {
    merge(s, cloneDeep(moduleState))
    s.form = cloneDeep(moduleState.form)
    s.companies = []
  },
}

export default {
  state: () => cloneDeep(moduleState),
  actions,
  mutations,
}