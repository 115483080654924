import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify);
Vue.use(VueI18n);


export default new Vuetify({
  theme: {
    dark: false ,
    themes: {
      light: {
        primary: '#272DBE',
        secondary: '#5BBAFA',
        accent: '#FF7A40',
        error: '#F20000',
        success: '#4BD1A0',
        dark: '#071048',
        darkSecondary: '#3A406C',
        disabled: '#AAAAB4',
        gray: '#626374',
        graySecondary: '#929BAC',
        graySeparator: '#B4C0D6'
      },
    },
  },
  icons: {
    iconfont: 'mdi' || 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
