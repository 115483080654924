

//utilities
// import moment from 'moment'
// import { rootDrawerMenuList, drawerMenuList } from '@/router'
// import { filter, cloneDeep, find, first, get, has, indexOf, isEmpty, merge, pick } from 'lodash'
// import api, {formatPhone } from '@/api' //api
// import { APP_ERROR } from './app'

import { cloneDeep, merge} from 'lodash'
import { defaultPaging } from '../Index' //updatePaging


// constants

export const RESET_USER_STATE = 'RESET_USER_STATE'
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE'
export const REQUEST_USER_LIST = 'REQUEST_USER_LIST'


// state
const moduleState = {
  isProcessing: false,
  isFetching: false,
  isFormAdd: false,
  isFormEdit: false,
  search: '',
  tabs: [
    {
      name: 'Associates',
      isActive: true,
    },
    {
      name: 'Customers',
      isActive: false,
    },
  ],
  items: [
    {
      status: 'active',
      name: 'Frozen Yogurt',
      calories: 159,
      fat: 6.0,
      carbs: 24,
      protein: 4.0,
      iron: '1%',
    },
    {
      status: 'inactive',
      name: 'Ice cream sandwich',
      calories: 237,
      fat: 9.0,
      carbs: 37,
      protein: 4.3,
      iron: '1%',
    },
    {
      status: 'active',
      name: 'Eclair',
      calories: 262,
      fat: 16.0,
      carbs: 23,
      protein: 6.0,
      iron: '7%',
    },
    {
      status: 'active',
      name: 'Cupcake',
      calories: 305,
      fat: 3.7,
      carbs: 67,
      protein: 4.3,
      iron: '8%',
    },
    {
      status: 'active',
      name: 'Gingerbread',
      calories: 356,
      fat: 16.0,
      carbs: 49,
      protein: 3.9,
      iron: '16%',
    },
    {
      status: 'pending',
      name: 'Jelly bean',
      calories: 375,
      fat: 0.0,
      carbs: 94,
      protein: 0.0,
      iron: '0%',
    },
    {
      status: 'pending',
      name: 'Lollipop',
      calories: 392,
      fat: 0.2,
      carbs: 98,
      protein: 0,
      iron: '2%',
    },
    {
      status: 'active',
      name: 'Honeycomb',
      calories: 408,
      fat: 3.2,
      carbs: 87,
      protein: 6.5,
      iron: '45%',
    },
    {
      status: 'active',
      name: 'Donut',
      calories: 452,
      fat: 25.0,
      carbs: 51,
      protein: 4.9,
      iron: '22%',
    },
    {
      status: 'active',
      name: 'KitKat',
      calories: 518,
      fat: 26.0,
      carbs: 65,
      protein: 7,
      iron: '6%',
    },
  ],
  item: {},
  paging: defaultPaging(),

}

// action

const actions = {

}

//Mutation
const mutations = {
  async [UPDATE_USER_STATE] (s, payload) {
    merge(s, payload)
    if (payload.items) s.items = [].concat(payload.items || [])
    if (payload.item) s.item = cloneDeep(payload.item)
  },
  async [RESET_USER_STATE] (s) {
    merge(s, cloneDeep(moduleState))
    s.items = []
    s.item = {}
  },
}

export default {
  state: () => cloneDeep(moduleState),
  actions,
  mutations,
}