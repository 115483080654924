

//utilities
// import moment from 'moment'
// import { rootDrawerMenuList, drawerMenuList } from '@/router'
// import { filter, cloneDeep, find, first, get, has, indexOf, isEmpty, merge, pick } from 'lodash'
import api from '@/api' //api //formatPhone
import { COLOR_INFO, SNACKBAR_ADD } from '@/store/Index' //COLOR_WARNING
import { APP_ERROR } from './app'

import { cloneDeep, merge} from 'lodash'
import { defaultPaging, updatePaging } from '../Index' //updatePaging


// constants

export const RESET_BYO_STORE_STATE = 'RESET_BYO_STORE_STATE'
export const UPDATE_BYO_STORE_STATE = 'UPDATE_BYO_STORE_STATE'

export const REQUEST_BYO_STORE_LIST = 'REQUEST_BYO_STORE_LIST'
export const REQUEST_BYO_CUSTOMER_LIST = 'REQUEST_BYO_CUSTOMER_LIST'
export const REQUEST_BYO_ADD_USER_QUEUE = 'REQUEST_BYO_ADD_USER_QUEUE'
export const REQUEST_BYO_UPDATE_USER_STATUS = 'REQUEST_BYO_UPDATE_USER_STATUS'



// state
const moduleState = {
  isCustomerSearch: false,
  isProcessing: false,
  isFetching: false,
  isFormAdd: false,
  isFormEdit: false,
  isAdd: false,
  search: '',
  userSearch: '',
  items:[],
  item: {},
  paging: defaultPaging(),
  customers: [],
  customer: {},
  customerPaaging: defaultPaging(),
  info: {
    estWaitTime: '',
    totalActive: 0,
    storeId: '',
  },
}

// action
export const actions = {
  async [REQUEST_BYO_STORE_LIST] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_BYO_STORE_STATE, { isFetching: true })
  
      const { paging } = state
      const res = await api.get(`/v1/stores/${101}/queues/type/${`byo_pc`}`, api.parseListingPayload({...paging, order: false, status: 'response,requested'}))
      await commit(UPDATE_BYO_STORE_STATE, {
        items: [
          ...api.parseItems(res).map(item => { 
            return {...item, customer_name: `${item?.created_by?.first_name ?? '-'} ${item?.created_by?.last_name ?? ''}`}
          })
        ],
        paging: updatePaging(api.parsePagination(res)),
        info: {
          estWaitTime: res?.data?.data?.estWaitTime,
          totalActive: res?.data?.data?.totalActive,
          storeId: res?.data?.data?.storeId,
        },
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_BYO_STORE_STATE, { isFetching: false })
  },
  async [REQUEST_BYO_CUSTOMER_LIST] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_BYO_STORE_STATE, { isCustomerSearch: true })
  
      const { paging, userSearch  } = state
      const res = await api.get(`/v1/customers`,{
        ...api.parseListingPayload(paging),
        name: userSearch,
      })
      await commit(UPDATE_BYO_STORE_STATE, {
        customers: api.parseItems(res),
        customerPaaging: updatePaging(api.parsePagination(res)),
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_BYO_STORE_STATE, { isCustomerSearch: false })
  },
  async [REQUEST_BYO_ADD_USER_QUEUE] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_BYO_STORE_STATE, { isProcessing: true })
  
      const { customer, info } = state

      const params = {
        createdById : customer.account_id,
        userType: "customer",
        store_id: info.storeId,
        location_id: "string",
        message: "string",
        type: "tech_bar",
        status: "requested"
      }
      const res = await api.post(`/v1/stores/${info.storeId}/queues/type/${'byo_pc'}`, params)
      
      await dispatch(SNACKBAR_ADD, {
        message: res.data.message,
        color: COLOR_INFO,
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_BYO_STORE_STATE, { isProcessing: false })
    await dispatch(REQUEST_BYO_STORE_LIST);
  },
  async [REQUEST_BYO_UPDATE_USER_STATUS] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_BYO_STORE_STATE, { isProcessing: true })
  
      const { item, info } = state;

      const params = {
        updateById: item.admin.id,
        userType: "employee",
        status: item.status,
      }
      const res = await api.put(`/v1/stores/${info.storeId}/queues/type/${'byo_pc'}/${item.id}`, params)
      
      await dispatch(SNACKBAR_ADD, {
        message: res.data.message,
        color: COLOR_INFO,
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await dispatch(REQUEST_BYO_STORE_LIST);
    await commit(UPDATE_BYO_STORE_STATE, { isProcessing: false })
  },
}

//Mutation
const mutations = {
  async [UPDATE_BYO_STORE_STATE] (s, payload) {
    merge(s, payload)
    if (payload.items) s.items = [].concat(payload.items || [])
    if (payload.item) s.item = cloneDeep(payload.item)
    if (payload.customers) s.customers = [].concat(payload.customers || [])
    if (payload.customer) s.customer = cloneDeep(payload.customer)
    if (payload.isFetching) s.isFetching = cloneDeep(payload.isFetching)

  },
  async [RESET_BYO_STORE_STATE] (s) {
    merge(s, cloneDeep(moduleState))
    s.items = []
    s.item = {}
    s.customers = []
    s.customer = {}
  }
}

export default {
  state: () => cloneDeep(moduleState),
  actions,
  mutations,
}