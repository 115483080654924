//Login
export const ROUTE_SIGNIN = 'SignIn'
export const ROUTE_SIGNUP = 'SignUp'
export const ROUTE_SIGNOUT = 'SignOut'
export const ROUTE_CONFIRM = 'Confirm'
export const ROUTE_RESET_PASSWORD = 'ResetPassword'
export const ROUTE_NEW_PASSWORD = 'NewPassword'

//App
export const ROUTE_DASHBOARD = 'Dashboard'
export const ROUTE_USERS = 'Users'
export const ROUTE_PRODUCTS = 'Products'
export const ROUTE_TECH_BAR = 'TechBarQueue'
export const ROUTE_STORE_SIGNS = 'StoreSigns'
export const ROUTE_AISLE_GUIDE = 'AisleGuide'
export const ROUTE_BUILD_YOUR_OWN = 'BuildYourOwn'
export const ROUTE_SURVEY = 'Survey'





