

//utilities
// import moment from 'moment'
// import { rootDrawerMenuList, drawerMenuList } from '@/router'
// import { filter, cloneDeep, find, first, get, has, indexOf, isEmpty, merge, pick } from 'lodash'
import api from '@/api' //api
import { APP_ERROR } from './app'

import { cloneDeep, merge} from 'lodash'
import { defaultPaging, updatePaging } from '../Index' //updatePaging

import { COLOR_INFO, SNACKBAR_ADD } from '@/store/Index' //COLOR_WARNING



// constants

export const RESET_SURVEY_STATE = 'RESET_SURVEY_STATE'
export const UPDATE_SURVEY_STATE = 'UPDATE_SURVEY_STATE'
export const REQUEST_SURVEY_LIST = 'REQUEST_SURVEY_LIST'
export const REQUEST_QUESTION_LIST = 'REQUEST_QUESTION_LIST'
export const REQUEST_ADD_QUESTION = 'REQUEST_ADD_QUESTION'
export const REQUEST_DELETE_QUESTION = 'REQUEST_DELETE_QUESTION'



// state
const moduleState = {
  isProcessing: false,
  isFetching: false,
  isFormAdd: false,
  isFormEdit: false,
  items: [],
  item: {},
  paging: defaultPaging(),

}

// action

const actions = {
  async [REQUEST_QUESTION_LIST] ({ commit, dispatch, state }, hideLoading) {
    try {
      if(!hideLoading){
        await commit(UPDATE_SURVEY_STATE, { isFetching: true })
      }else {
        await commit(UPDATE_SURVEY_STATE, { isFetching: false })
      }
  
      const { paging } = state
      const res = await api.get(`/v1/questions`, api.parseListingPayload({...paging, order: false, status: 'response,requested'}))
      await commit(UPDATE_SURVEY_STATE, {
        items: api.parseItems(res),
        paging: updatePaging(api.parsePagination(res))
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_SURVEY_STATE, { isFetching: false })
  },
  async [REQUEST_ADD_QUESTION] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_SURVEY_STATE, { isProcessing: true })
  
      const { item } = state;
      let answer = item.choices.map(ans => ans.text);
      const params = {
        title: item.title,
        answers: answer,
        type: item.type,
      }
      const res = await api.post(`/v1/questions`, params);

      await dispatch(SNACKBAR_ADD, {
        message: res.data.message,
        color: COLOR_INFO,
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_SURVEY_STATE, { isProcessing: false })
    await dispatch(REQUEST_QUESTION_LIST, true);
  },
  async [REQUEST_DELETE_QUESTION] ({ commit, dispatch, state }) {
    try {
      await commit(UPDATE_SURVEY_STATE, { isProcessing: true })
  
      const { item } = state;
      const res = await api.delete(`/v1/questions/${item.id}`);

      await dispatch(SNACKBAR_ADD, {
        message: res.data.message,
        color: COLOR_INFO,
      })
    } catch (error) {
      await dispatch(APP_ERROR, { error })
    }
    await commit(UPDATE_SURVEY_STATE, { isProcessing: false })
    await dispatch(REQUEST_QUESTION_LIST, true);
  },

}

//Mutation
const mutations = {
  async [UPDATE_SURVEY_STATE] (s, payload) {
    merge(s, payload)
    if (payload.items) s.items = [].concat(payload.items || [])
    if (payload.item) s.item = cloneDeep(payload.item)
  },
  async [RESET_SURVEY_STATE] (s) {
    merge(s, cloneDeep(moduleState))
    s.items = []
    s.item = {}
  },
}

export default {
  state: () => cloneDeep(moduleState),
  actions,
  mutations,
}